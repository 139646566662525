let text_list, text, first_text
let text_index = 0
const typingDiv = document.getElementById('typing-effect');

let index = 0;
let deleting = false;

let cursor = true;
let speed = 350;

function type() {
    if (!deleting) {
        typingDiv.innerHTML = first_text.replace('%msg%', text.slice(0, index++)) + '<span id="cursor" style="font-family: Hellix-Light, sans-serif">|</span>';
        if (index <= text.length) {
            setTimeout(type, 60);
        } else {
            deleting = true;
            setTimeout(deleteText, 4000);
        }
    }
}

function deleteText() {
    if (index >= 0) {
        typingDiv.innerHTML = first_text.replace('%msg%', text.slice(0, index--)) + '<span id="cursor" style="font-family: Hellix-Light, sans-serif">|</span>';
        setTimeout(deleteText, 60);
    } else {
        if (text_index + 1 <= text_list.length - 1) {
            text_index += 1
        } else {
            text_index = 0
        }
        text = text_list[text_index];
        index = 0;
        deleting = false;
        setTimeout(type, 1500);
    }
}


window.addEventListener('onloadtraduction', async function () {
    text_list = [String(traductions['home_msg3']), String(traductions['home_msg4']), String(traductions['home_msg5']), String(traductions['home_msg6']), String(traductions['home_msg7']), String(traductions['home_msg8']), String(traductions['home_msg9']), String(traductions['home_msg10'])]
    text = text_list[text_index];
    first_text = traductions['home_msg0']
    type();
    setInterval(() => {
        if(cursor) {
            document.getElementById('cursor').style.opacity = 0;
            cursor = false;
        }else {
            document.getElementById('cursor').style.opacity = 1;
            cursor = true;
        }
    }, speed);
})

/*==================== SCROLL ====================*/
function scrollHeader(){
    const scrollheader = document.getElementsByClassName('l-header')[0];
    if(this.scrollY >= 300) scrollheader.style.backgroundColor = "#151416"; else scrollheader.style.backgroundColor = "transparent"
}
window.addEventListener('scroll', scrollHeader)

/*==================== SCROLL REVEAL ANIMATION ====================*/
const sr = ScrollReveal({
    origin: 'top',
    distance: '150px',
    duration: 2000,
    reset: false
});


sr.reveal(`.home__card_container, .home__card_final_container`, {
    interval: 400
})